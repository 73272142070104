import styled, { css } from "styled-components"
import theme from "../../../config/theme"
import { mq } from "../../../config/mq"
// import { truncate } from "../../../config/mixins"
import { fontSize, lineHeight, space } from "styled-system"
// import { Link } from "gatsby"
// import { transparentize } from "polished"

///

const bodyTypoStyle = css`
  a:not([class]) {
    &:hover {
      color: ${theme.colors.accent};
    }
  }

  figcaption {
    text-align: center;
    font-size: 90%;
    opacity: 0.75;
    margin-top: -0.5rem;
    margin-bottom: 2rem;
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.125;
    margin-top: 2rem;
    margin-bottom: 1em;
    letter-spacing: -0.01em;
  }

  iframe {
    width: 100%;
    min-height: 56vw;

    ${mq.sm(`min-height: 21rem;`)}
  }

  img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2rem;
  }

  p {
    white-space: pre-line;

    .gatsby-resp-image-wrapper {
      white-space: normal;
    }
  }

  ul,
  ol {
    margin-left: 1rem;
  }

  p:not(:first-child) .gatsby-resp-image-wrapper {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    ${mq.sm(
      `margin-top: calc(1.5rem + 1vw); margin-bottom: calc(1.5rem + 1vw);`
    )}
    ${mq.lg(`margin-top: 2.5rem; margin-bottom: 2.5rem;`)}
  }

  > p,
  > ul,
  > ol,
  > div {
    margin-bottom: 1rem;
  }
`

const headingSizes = css`
  h2 {
    font-size: 1.6em;
    ${mq.sm(`font-size: 1.8em;`)}
  }
  h3 {
    font-size: 1.424em;
    ${mq.sm(`font-size: 1.6em;`)}
  }
  h4 {
    font-size: 1.266em;
    ${mq.sm(`font-size: 1.424em;`)}
  }
  h5 {
    font-size: 1.125em;
    ${mq.sm(`font-size: 1.266em;`)}
  }
  h6 {
    font-size: 1em;
    ${mq.sm(`font-size: 1.125em;`)}
  }
`

export const BodyText = styled.div`
  ${fontSize}
  ${lineHeight}
  ${space}

  ${bodyTypoStyle}
  ${headingSizes}
`
BodyText.defaultProps = {
  fontSize: 2,
  lineHeight: 4,
}

///

export const PageTitle = styled.h1`
  ${fontSize}
  ${lineHeight}
  ${space}

  text-transform: uppercase;
  text-align: center;
  max-width: 16em;
`
PageTitle.defaultProps = {
  fontSize: [7, 8, 9, 10],
  lineHeight: 2,
  mx: "auto",
}
