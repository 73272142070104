import React from "react"
import { graphql } from "gatsby"
import NotFoundPage from "./error-not-found"
import Layout from "../components/Layout"
import { BodyText, PageTitle } from "../components/Typography"
import SEO from "../components/seo"

///

class Page extends React.Component {
  render() {
    const page = this.props.data.markdownRemark
    const baseUrl = this.props.data.site.siteMetadata.siteUrl

    const seoUrl = baseUrl + page.fields.slug.replace("/pages/", "/")

    return page.fields.slug.includes("404") ? (
      <NotFoundPage {...this.props} />
    ) : (
      <Layout>
        <SEO
          title={page.frontmatter.title}
          description={page.frontmatter.description || page.excerpt}
          url={seoUrl}
        />
        <div className="AcLayout__outer-container">
          <PageTitle mb={[4, 5]}>{page.frontmatter.title}</PageTitle>
          <BodyText dangerouslySetInnerHTML={{ __html: page.html }} />
        </div>
      </Layout>
    )
  }
}

export default Page

export const pageQuery = graphql`
  query PageBySlug($slug: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt
      html
      fields {
        slug
      }
      frontmatter {
        title
        description
      }
    }
  }
`
