import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/Layout"
import Button from "../components/Button"
import SEO from "../components/seo"
import styled from "styled-components"
import { fontSize, space } from "styled-system"

///

const ContentWrapper = styled.div`
  min-height: 30rem; // fallback
  height: calc(100vh - 10rem);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  p:not(:last-child) {
    margin-bottom: 2em;
  }
`

const Title = styled.h2`
  ${fontSize}
  ${space}
`

const BackButton = styled(Button)`
  background: transparent !important;
`

class NotFoundPage extends React.Component {
  render() {
    const { data } = this.props
    const { markdownRemark } = data
    let pageTitle
    let pageContent
    if (markdownRemark) {
      pageTitle = markdownRemark.frontmatter.title
      pageContent = markdownRemark.html
    }

    return (
      <Layout showHeader={false} activeTheme="dark">
        <SEO title={pageTitle || "Page Not Found"} />
        <ContentWrapper>
          <Title fontSize={[10, 11, 12]} mb={4}>
            {pageTitle || "Page Not Found"}
          </Title>
          {pageContent ? (
            <div dangerouslySetInnerHTML={{ __html: pageContent }} />
          ) : (
            <p>You just hit a route that doesn&#39;t exist...</p>
          )}
          <BackButton as={Link} to="/" mt={4}>
            ← Comics by Azam Raharjo
          </BackButton>
        </ContentWrapper>
      </Layout>
    )
  }
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    custom404: markdownRemark(fileAbsolutePath: { regex: "/404.md/" }) {
      frontmatter {
        title
      }
      html
    }
  }
`
